import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next";
import InnerPage from "../components/innerPage";

const NotFoundPage = ({data, location}) => {
    const {t} = useTranslation();
    const {language, originalPath} = useI18next();

    // const breadcrumbs = [
    //     {
    //         title: t('LovingKidsFullName'),
    //     }
    // ]

    return (
        // <Layout location={location} title={siteTitle}>
        <Layout>
            <Seo title={t('404NotFoundTitle')}/>
            {/*  <br/><br/><br/><br/><br/>*/}
            {/*  {JSON.stringify(location)}*/}
            {/*  <br/>*/}
            {/*  {JSON.stringify(data)}*/}
            {/*<h1>404: Not Found</h1>*/}
            {/*<p>You just hit a route that doesn&#39;t exist... the sadness.</p>*/}
            <InnerPage
                // breadcrumbs={breadcrumbs}
                pageTitle={t('pageNotFound')}
            >
                <div className={"row text-center"}>
                    <h3>{t('404NotFoundTitle')}</h3>
                </div>
            </InnerPage>
        </Layout>
    )
}

export default NotFoundPage

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
